import 'regenerator-runtime';
import * as d3 from 'd3';
import utils from '/app/components/utils';
import './squares.scss';

const squares = (data, options) => {
  const defaultConfig = {
    distance: 2,
    fill: null,
    height: 0,
    id: null,
    devicePixelRatio: window.devicePixelRatio || 1,
    position: 'centered',
    ratio: 10,
    shape: 'square',
    size: 2,
    stroke: null,
    strokeWidth: 1,
    target: null,
    targetObject: null,
    width: 0,
  };
  
  const init = () => {
    const currentWidth = config.width * config.devicePixelRatio;
    const currentHeight = config.height * config.devicePixelRatio;
    const currentSize = config.size * config.devicePixelRatio;
    const currentDistance = config.distance * config.devicePixelRatio;
    const currentStrokeWidth = config.strokeWidth * config.devicePixelRatio;

    const container = config.targetObject.append('div')
      .attr('class', 'pointCharts')
      .attr('id', config.id);
    
    const cnvs = container.append('canvas')
      .attr('id', `canavs-${config.id}`)
      .attr('width', currentWidth)
      .attr('height', currentHeight)
      .attr('style', `width: ${config.width}px; height: ${config.height}px`);
    
    const ctx = cnvs.node().getContext('2d');

    const shapes = Math.round(data / config.ratio);
    const shapesPerLine = Math.round(Math.sqrt(shapes));
    
    let offsetX = 1;
    let offsetY = 1;
    switch (config.position) {
      case 'centered':
        offsetX = (Math.floor(currentWidth / 2)) - Math.round(((currentSize + currentDistance) * shapesPerLine) / 2);
        offsetY = (Math.floor(currentHeight / 2)) - Math.round(((currentSize + currentDistance) * Math.ceil(shapes / shapesPerLine)) / 2);
        break;
      case 'bottom-right':
        offsetX = currentWidth - ((currentSize + currentDistance) * shapesPerLine);
        offsetY = currentHeight - ((currentSize + currentDistance) * Math.ceil(shapes / shapesPerLine));
        break;
      case 'top-right':
        offsetX = ccurrentWidth - ((currentSize + currentDistance) * shapesPerLine);
        offsetY = 1;
        break;
      case 'bottom-left':
        offsetX = 1;
        offsetY = currentHeight - ((currentSize + currentDistance) * Math.ceil(shapes / shapesPerLine));
        break;
      case 'top-center':
        offsetX = (Math.floor(currentWidth / 2)) - Math.round(((currentSize + currentDistance) * shapesPerLine) / 2);
        offsetY = 1;
        break;
      default:
        offsetX = 1;
        offsetY = 1;
        break;
    }

    ctx.fillStyle = config.fill;
    ctx.strokeStyle = config.stroke;
    ctx.strokeWidth = currentStrokeWidth;

    for (let shape = 0; shape < shapes; shape++) {
      if (config.shape === 'circle') {
        const xPos = offsetX + (shape % shapesPerLine) * (currentDistance + currentSize) + Math.floor(currentSize / 2);
        const yPos = offsetY + Math.floor(shape / shapesPerLine) * (currentDistance + currentSize) + Math.floor(currentSize / 2);
        const radius = (config.stroke) ? Math.floor((currentSize / 2) - currentStrokeWidth / 2) : Math.floor(currentSize / 2);
        ctx.beginPath();
        ctx.arc(xPos, yPos, radius, 0, 2 * Math.PI);
        if (config.fill) {
          ctx.fill();
        }
        if (config.stroke) {
          ctx.stroke();
        }
      } else {
        const xPos = offsetX + (shape % shapesPerLine) * (currentDistance + ccurrentSize);
        const yPos = offsetY + Math.floor(shape / shapesPerLine) * (currentDistance + ccurrentSize);
        if (config.fill) {
          ctx.fillRect(xPos, yPos, currentSize, currentSize);
        }
        if (config.stroke) {
          ctx.strokeRect(xPos + Math.round(currentStrokeWidth / 2), yPos + Math.round(currentStrokeWidth / 2), currentSize - currentStrokeWidth, currentSize - currentStrokeWidth);
        }
      }
    }
  }
  
  let config = utils.merge(defaultConfig, options);
  config.targetObject = d3.select(config.target);  
  if (
    config.id
    && config.target
    && !config.targetObject.empty()
    && config.height > 0
    && config.width > 0
  ) {
    init();
  } else {
    console.error('Squares failed initialization');
  }
};

export default squares;