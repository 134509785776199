import _ from 'lodash';

const utils = {
  merge: (obj1, obj2) => {
    return _.merge(obj1, obj2); 
  },
  sizes: {
    current: 's',
    getSize: () => utils.sizes.current,
    setSize: () => {
      if (window.matchMedia('screen and (min-width: 1280px)').matches) {
        utils.sizes.current = 'l';
      } else if (window.matchMedia('screen and (min-width: 768px)').matches) {
        utils.sizes.current = 'm';
      } else {
        utils.sizes.current = 's';
      }
      return utils.sizes.current;
    }
  },
  unique: (arr, prop) => {
    console.log(arr, prop, _.uniqBy(arr, prop));
    return _.uniqBy(arr, prop);
  },
}

export default utils;
