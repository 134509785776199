import 'regenerator-runtime';
import * as d3 from 'd3';
import utils from '/app/components/utils';
import squares from '/app/components/squares';
import './canvasVocabulary.scss';


const canvasVocabulary = () => {
  let size = utils.sizes.setSize();
  const items = [
    {
      data: 100000,
      distance: () => ((size === 'l') ? 4 : 2),
      fill: null,
      id: 'total',
      label: 'Parole Totali',
      shape: 'circle',
      size: () => ((size === 'l') ? 10 : 8),
      stroke: 'rgba(0, 0, 0, .2)',
      strokeWidth: () => ((size === 'l') ? 2 : 1),
      target: '#vocabolario-totale',
    },
    {
      data: 47000,
      distance: () => ((size === 'l') ? 4 : 2),
      fill: null,
      id: 'lessicoComune',
      label: 'Lessico Comune',
      shape: 'circle',
      size: () => ((size === 'l') ? 10 : 8),
      stroke: 'rgba(0, 0, 0, .4)',
      strokeWidth: () => ((size === 'l') ? 2 : 1),
      target: '#vocabolario-lessicoComune',
    },
    {
      data: 6800,
      distance: () => ((size === 'l') ? 4 : 2),
      fill: null,
      id: 'vocabolarioBase',
      label: 'Vocabolario Base',
      shape: 'circle',
      size: () => ((size === 'l') ? 10 : 8),
      stroke: 'rgba(0, 0, 0, .5)',
      strokeWidth: () => ((size === 'l') ? 2 : 1),
      target: '#vocabolario-vocabolarioBase',
    },
    {
      data: 3028,
      distance: () => ((size === 'l') ? 4 : 2),
      fill: 'red', // 'rgba(241, 123, 215, 1)',
      id: 'vocabolarioGhali',
      label: 'Vocabolario di Ghali',
      shape: 'circle',
      size: () => ((size === 'l') ? 10 : 8),
      stroke: 'red', // 'rgba(241, 123, 215, 1)',
      strokeWidth: () => ((size === 'l') ? 2 : 1),
      target: '#vocabolario-vocabolarioGhali',
    }
  ];

  const target = '#vocabolario-container';

  const draw = () => {
    size = utils.sizes.setSize();
    items.forEach(i => {
      const targetObject = d3.select(i.target);
      console.log(targetObject);
      targetObject.html('');
      const ratio = (() => {
        switch (size) {
          case 'l': 
            return 50;
          case 'm':
            return 100;
          default:
            return 250;
        }
      })();      
      d3.select('#vocabolario-ratio').text(ratio);      
      if (!targetObject.empty()) {
        squares(
          i.data,
          {
            distance: i.distance(),
            fill: i.fill,
            height: targetObject.node().offsetHeight,
            id: i.id,
            position: 'top-left',
            ratio,
            shape: i.shape,
            size: i.size(),
            stroke: i.stroke,
            target: i.target,
            strokeWidth: i.strokeWidth(),
            width: targetObject.node().offsetWidth,
          }
        );
      }
    });
  };

  const init = () => {
    draw();
    window.addEventListener(
      'resize',
      () => {
        console.log('resize');
        setTimeout(
          draw,
          50
        );
      }
    );
  }

  setTimeout(
    init,
    10
  );
}

export default canvasVocabulary;