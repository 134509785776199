// Components
import utils from '/app/components/utils';
import beeswarmWords from '/app/beeswarmWords';
import canvasVocabulary from '/app/canvasVocabulary';
// Css
import '/app/libraries/scrolly.min.js';
import '/app/libraries/cssanimation.min.css';
import './index.scss';


(() => {
  const init = () => {
    beeswarmWords();
    canvasVocabulary();
  };

  const ready = () => {
    if (document.readyState != 'loading') {
      init();
      scrolly();
    } else {
        document.addEventListener('DOMContentLoaded', init);
    }
  };
  setTimeout(
    ready,
    10
  );
})();