import 'regenerator-runtime';
import * as d3 from 'd3';
import utils from '/app/components/utils';
import './beeswarm.scss';

const beeswarm = (data, options) => {
  const defaultConfig = {
    chart: {
      axis: {
        show: true,
        tickNumber: 'auto',
        tickFormat: '.0s',
      },
      height: 0,
      hover: {
        function: null,
        highlight: false,
      },
      margins: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
      targetObject: null,
      target: null,
      width: 0,
    },
    points: {
      className: 'point',
      hover: null,
      idNodeName: null,
      padding: 2,
      radius: 5,
      valueNodeName: 'value',
    },
  };

  const sizes = () => {
    config.chart.height = config.chart.targetObject.node().offsetHeight;
    config.chart.width = config.chart.targetObject.node().offsetWidth;
  }

  const dodge = (data, radius, x) => {
    const radius2 = radius ** 2;
    const circles = data.map(d => ({x: x(d[config.points.valueNodeName]), data: d})).sort((a, b) => a.x - b.x);
    const epsilon = 1e-3;
    let head = null, tail = null;
    // Returns true if circle ⟨x,y⟩ intersects with any circle in the queue.
    function intersects(x, y) {
      let a = head;
      while (a) {
        if (radius2 - epsilon > (a.x - x) ** 2 + (a.y - y) ** 2) {
          return true;
        }
        a = a.next;
      }
      return false;
    }
    // Place each circle sequentially.
    for (const b of circles) {
      // Remove circles from the queue that can’t intersect the new circle b.
      while (head && head.x < b.x - radius2) head = head.next;
      // Choose the minimum non-intersecting tangent.
      if (intersects(b.x, b.y = 0)) {
        let a = head;
        b.y = Infinity;
        do {
          let y1 = a.y + Math.sqrt(radius2 - (a.x - b.x) ** 2);
          let y2 = a.y - Math.sqrt(radius2 - (a.x - b.x) ** 2);
          if (Math.abs(y1) < Math.abs(b.y) && !intersects(b.x, y1)) b.y = y1;
          if (Math.abs(y2) < Math.abs(b.y) && !intersects(b.x, y2)) b.y = y2;
          a = a.next;
        } while (a);
      }
      // Add b to the queue.
      b.next = null;
      if (head === null) head = tail = b;
      else tail = tail.next = b;
    }
    return circles;
  }

  const update = (data, options) => {
    chartData = data;
    if (options) {
      config = utils.merge(defaultConfig, options);
    }
    refresh();
  }

  const refresh = () => {
    config.chart.targetObject.node().innerHTML = '';
    sizes();
    draw();
    setMouseInteraction();
    hover();
  };

  const setMouseInteraction = () => {
    const dataPoints = document.querySelectorAll(`${config.chart.target} circle`);
    matrix = [];
    dataPoints.forEach((c) => {
      const exists = matrix.find(m => m.value === parseInt(c.getAttribute('data-value')));
      if (!exists) {
        matrix.push({
          value: parseInt(c.getAttribute('data-value'), 10),
          x: parseInt(c.getAttribute('cx'), 10),
          x1: parseInt(c.getAttribute('cx'), 10) - config.points.radius,
          x2: parseInt(c.getAttribute('cx'), 10) + config.points.radius,
          words: chartData.filter(d => d[config.points.valueNodeName] === parseInt(c.getAttribute('data-value'))),
        });
      }
    });
  }

  const draw = () => {
    container = config.chart.targetObject.append('div')
      .attr('class', 'beeswarm');
    svg = container.append('svg')
      .attr('width', `${config.chart.width}px`)
      .attr('height', `${config.chart.height}px`)
      .attr('class', 'beeswarm')
      .attr('viewbox', `0 0 ${config.chart.width} ${config.chart.height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet');
    innerWidth = config.chart.width - config.chart.margins.left - config.chart.margins.right;
    innerHeight = config.chart.height - config.chart.margins.top - config.chart.margins.bottom;

    x = d3.scaleLinear()
      .rangeRound([0, innerWidth])
      .domain(d3.extent(chartData, (d) => d[config.points.valueNodeName]));
      
    const g = svg.append("g")
      .attr("transform", `translate(${config.chart.margins.left},${config.chart.margins.top})`);
    
    if (config.chart.axis.show) {
      if (config.chart.axis.type === 'custom') {
        const custom = g.append("g")
          .attr("class", "beeswarm-axis beeswarm-axis")
          .attr("transform", `translate(0,${innerHeight})`);
        const keys = Object.keys(config.chart.axis.entries);
        keys.forEach(k => {
          const className = config.chart.axis.entries[k].className ? config.chart.axis.entries[k].className : '';
          let xPos;
          let textAnchor;
          switch(k) {
            case 'start':
              xPos = 0;
              textAnchor = 'start';
              break;
            case 'middle':
              xPos = innerWidth / 2;
              textAnchor = 'middle';
              break;
            default:
              xPos = innerWidth;
              textAnchor = 'end';
              break;
          }
          custom.append('text')
            .attr('x', xPos)
            .attr('y', 0)
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', textAnchor)
            .attr('class', `beeswarm-axis-custom ${className}`)
            .text(config.chart.axis.entries[k].text);
        });
        
          
      } else {
        let tickNumber = Math.floor((x.domain()[1] - x.domain()[0]) / 10);
        if (!isNaN(parseInt(config.chart.axis.tickNumber))) {
          tickNumber = parseInt(config.chart.axis.tickNumber);
        }
        g.append("g")
          .attr("class", "beeswarm-axis beeswarm-axis")
          .attr("transform", `translate(0,${innerHeight})`)
          .call(d3.axisBottom(x).ticks(tickNumber, config.chart.axis.tickFormat));
      }
    }

    const circlesContainer = svg.append("g")
      .attr("transform", `translate(${config.chart.margins.left},${config.chart.margins.top})`)
      .attr("class", "beeswarm-data-container");
    
    circles = circlesContainer.selectAll("circle")
      .data(dodge(chartData, config.points.radius * 2 + config.points.padding, x))
      .join("circle")
        .attr("cx", d => d.x)
        .attr("cy", d => innerHeight / 2 + d.y)
        .attr("r", config.points.radius)
        .attr("data-value", d => d.data[config.points.valueNodeName])
        .attr("class", d => `beeswarm-${config.points.className} ${(config.points.idNodeName ? 'beeswarm-' + config.points.className + '-' + d.data[config.points.idNodeName] : '')} beeswarm-value-${d.data.count}`)
        .attr("id", d => config.points.idNodeName ? d.data[config.points.idNodeName] : null);
  
    const anntns = svg.append("g")
      .attr("transform", `translate(${config.chart.margins.left},${config.chart.margins.top})`)
      .attr("class", "beeswarm-annotations")
    const annotations = chartData.filter(d => d.annotations);
    annotations.forEach(a => {
      const selectedCircles = document.querySelectorAll(`${config.chart.target} circle[data-value="${a[config.points.valueNodeName]}"]`);
      if (a.annotations.top) {
        let x = 0;
        let y = Number.MAX_SAFE_INTEGER;
        selectedCircles.forEach(sc => {
          const cy = parseInt(sc.getAttribute('cy'));
          x = parseInt(sc.getAttribute('cx'));
          y = (y > cy) ? cy : y;
        });
        if (a.annotations.top.image && a.annotations.top.image.source) {
          anntns
            .append('image')
            .attr('class', `beeswarm-annotation-top beeswarm-annotation-top-image ${a.annotations.top.className}`)
            .attr('x', x - (a.annotations.top.image.width || 10) / 2)
            .attr('y', y - (config.points.radius * 2) - (a.annotations.top.image.height || 10)) 
            .attr('width', a.annotations.top.image.width || 10)
            .attr('height', a.annotations.top.image.height || 10)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .attr('transform', `${a.annotations.top.transform ? a.annotations.top.transform.replace(/\[x\]/ig, x).replace(/\[y\]/ig, y) : 'rotate(0 0 0)'}`)
            .attr('href', a.annotations.top.image.source);
        } else if (a.annotations.top.text) {
          anntns
            .append('text')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', `-${config.points.radius * 4}`)
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .attr('class', `beeswarm-annotation-top ${a.annotations.top.className}`)
            .attr('transform', `${a.annotations.top.transform ? a.annotations.top.transform.replace(/\[x\]/ig, x).replace(/\[y\]/ig, y) : 'rotate(0 0 0)'}`)
            .text(a.annotations.top.text);
        }
      }
      if (a.annotations.bottom) {
        let x = 0;
        let y = 0;
        selectedCircles.forEach(sc => {
          const cy = parseInt(sc.getAttribute('cy'));
          x = parseInt(sc.getAttribute('cx'));
          y = (y < cy) ? cy : y;
        });
        anntns
          .append('text')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', `${config.points.radius * 4}`)
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'middle')
          .attr('class', `beeswarm-annotation-top ${a.annotations.bottom.className}`)
          .attr('transform', `${a.annotations.bottom.transform ? a.annotations.bottom.transform.replace(/\[x\]/ig, x).replace(/\[y\]/ig, y) : 'rotate(0 0 0)'}`)
          .text(a.annotations.bottom.text);
      }
    });
  };

  const hover = () => {
    let highlighter = container.select('.beeswarm-highlighter');
    if (highlighter.empty()) {
      highlighter = container
        .append('div')
        .attr('class', 'beeswarm-highlighter');
    }
    let tooltip = container.select('.beeswarm-tooltip');
    if (tooltip.empty()) {
      tooltip = container
        .append('div')
        .attr('class', 'beeswarm-tooltip');
    }
    document.querySelector(`${config.chart.target} svg`).addEventListener('mousemove', (e) => {
      const element = matrix.filter(m => m.x1 <= (e.offsetX - config.chart.margins.left) && m.x2 >= (e.offsetX - config.chart.margins.left));
      if (element.length) {
        const tooltipText = config.chart.hover.function(element[0], e);
        if (config.chart.hover.highlight) {
          highlighter
            .attr('style', `display: block; left: ${element[0].x1 + config.chart.margins.left}px; opacity: 1; width: ${config.points.radius * 2}px`);
        }
        const tooltipPosition = element[0].x > innerWidth / 2 ? 'reverse' : 'normal';
        tooltip
          .html(tooltipText)   
          .attr('class', `beeswarm-tooltip ${tooltipPosition}`)
          .attr('style', `display: block; opacity: 1; left: ${(tooltipPosition === 'reverse') ? element[0].x1 + config.chart.margins.left : element[0].x2 + config.chart.margins.left}px; bottom: ${config.chart.margins.bottom}px`);
      } else {
        if (config.chart.hover.highlight) {
          highlighter
            .attr('style', 'display: none; opacity: 0');
        }
        tooltip
          .attr('class', 'beeswarm-tooltip')
          .attr('style', 'display: none; opacity: 0')
          .html('');
      }
    });
    document.querySelector(`${config.chart.target}`).addEventListener('mouseleave', (e) => {
      if (config.chart.hover.highlight) {
        highlighter
          .attr('style', 'display: none; opacity: 0');
      }
      tooltip
        .attr('class', 'beeswarm-tooltip')
        .attr('style', 'display: none; opacity: 0')
        .html('');
    });
  }

  const init = () => {
    if (config.chart.targetObject) {
      refresh();
      if (config.chart.hover.function) {
        hover();        
      }
      return { resize: refresh, update };
    }
    return null;
  };



  let matrix = [];
  let config = utils.merge(defaultConfig, options);
  config.chart.targetObject = d3.select(config.chart.target);
  sizes();
  let chartData = data;
  let circles;
  let x;
  let innerWidth = 0;
  let innerHeight = 0;
  let svg;
  let container;
  return init();
}

export default beeswarm;