import 'regenerator-runtime';
import beeswarm from '/app/components/beeswarm';
import utils from '/app/components/utils';
// Data
import beeswarmData from '/app/data/beeswarm.json';
// Images
import ghaliIcon from './assets/icon-ghali.png';
import albumDNA from './assets/album-dna.png';
import albumAlbum from './assets/album-album.png';
import albumLungaVita from './assets/album-lunga-vita.png';


const beeswarmWords = () => {
  const size = utils.sizes.setSize();
  const ALBUMS = [
    { album: 'DNA', image: albumDNA },
    { album: 'Album', image: albumAlbum },
    { album: 'Lunga vita a sto', image: albumLungaVita },
  ];

  const init = async () => {
    let timeout;
    const aggregator = { position: 1000, count: 0 };
    beeswarmData.forEach(b => {
      if (b.count !== aggregator.count) {
        aggregator.count = b.count;
        aggregator.position -= 1;
      }
      b.position = aggregator.position;
    });
    beeswarmData[0].annotations = {
      top: {
        className: 'icon',
        text: '🙅‍♂️',
        transform: 'rotate(15 [x] [y])',
      },
      bottom: {
        className: 'text-xl',
        text: `${beeswarmData[0].word}`,
      }
    }
    let idx;
    if (idx = beeswarmData.findIndex(b => b.word === 'ghali')) {
      beeswarmData[idx].annotations = {
        top: {
          className: 'ghali-icon',
          image: {
            width: 60,
            height: 60,
            source: ghaliIcon
          },
        },
      };
    }
    if (idx = beeswarmData.findIndex(b => b.word === 'mamma')) {
      beeswarmData[idx].annotations = {
        top: {
          className: 'icon',
          text: '👩‍👦',
          transform: 'rotate(15 [x] [y])',
        },
        bottom: {
          className: 'text-s',
          text: beeswarmData[idx].word,
        }
      };
    }    
    if (beeswarmData.length) {
      const beeswarmDetailChart = beeswarm(
        beeswarmData,
        {
            chart: {
              axis: {
                show: true,
                type: 'custom',
                entries: {
                  start: {
                    className: 'words-axis',
                    text: '← Meno usate',
                  },
                  middle: {
                    className: 'words-axis',
                    text: 'Frequenza di utilizzo',
                  },
                  end: {
                    className: 'words-axis',
                    text: 'Più usate →'
                  }
                }
              },
              hover: {
                function: (element) => {
                  const words = element.words.map(w => w.word);
                  const years = utils.unique(element.words.map(w => w.songs.map(s => ({ year: s.year })))[0], 'year');
                  const albums = utils.unique(element.words.map(w => w.songs.map(s => ({album: s.album, year: s.year})))[0], 'album');
                  const songs = utils.unique(element.words.map(w => w.songs.map(s => ({song: s.title, album: s.album, year: s.year})))[0], 'title');
                  let htmlAlbums = '';
                  albums.forEach(a => {
                    htmlAlbums += `<img src="${ALBUMS.find(A => A.album.toLowerCase() === a.album.toLowerCase()).image}" alt="${a.album}" /> `;
                  });
                  if (words.length === 1) {
                    return `<div class="words-inner-tooltip"><h2>${element.words[0].count}</h2><div><p>La parola "${words[0]}" è l'unica ad apparire <strong>${element.words[0].count}</strong> volte.</p><p>Contenute in:<br />${htmlAlbums}</p></div></div>`;
                  } else {
                    return `<div class="words-inner-tooltip"><h2>${element.words[0].count}</h2><div><p>Le parole ripetute <strong>${element.words[0].count}</strong> volte sono ${words.length}: "${words.join('", "')}".</p><p>Contenute in:<br />${htmlAlbums}</p></div></div>`;
                  }
                },
                highlight: true,
              },
              margins: (size === 's') ? { left: 15, right: 25, bottom: 20 } : { left: 25, right: 25, bottom: 20 },
              target: '#beeswarm-detail',
            },
            points: {
              className: 'word',
              idNodeName: 'word',
              padding: 1.5,
              radius: 6,
              valueNodeName: 'position',
            },
        }
      );
      window.addEventListener('resize', () => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          beeswarmDetailChart.resize();
        }, 50);
      });
    }
  };

  setTimeout(
    init,
    50
  );
}

export default beeswarmWords;